<template>
  <div :class="styles.itemContent">
    <div>
      <div
        v-if="ad.price"
        :class="styles.itemPrice"
      >
        €{{ ad.price }}
      </div>
      <Badge
        :class="styles.itemBadge"
        :design="badgeStatusDesign"
      >
        {{ ad.status.replace('_', ' ') }}
      </Badge>
      <h2>
        <Component
          :is="ad.status === 'published' ? 'a' : 'span'"
          :href="ad.url"
        >
          {{ ad.title }}
        </Component>
      </h2>
      <ListItemData
        :ad-type="ad.type"
        :age="ad.age"
        :breeds="ad.breeds"
        :county="ad.county"
        :dogs-info="ad.dogs_info"
      />
      <div
        :class="styles.itemDescription"
        v-html="ad.description"
      />
      <div :class="styles.itemBottomBar">
        <div :class="styles.itemButtonsWrapper">
          <slot
            v-if="slots.buttons"
            name="buttons"
          />
          <Button
            v-else-if="ad.status === 'published'"
            component="a"
            :href="ad.url"
          >
            View Ad
          </Button>
        </div>
        <div :class="styles.itemInfoWrapper">
          <img
            v-if="ad.verified_veripet"
            src="/img/icons/veripet.svg"
            alt="Verified by VeriPet"
            data-bs-toggle="tooltip"
            title="Verified by Veripet"
          >
          <FavouriteButton
            v-if="favouriteAds && !['expired', 'refunded'].includes(ad.status)"
            :favourited-id="favouriteAds[ad.id]"
            :model-id="ad.id"
            :model-type="ad.type"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, useSlots } from 'vue';

/* Components */
import Badge from '@components/Shared/Layout/Badge';
import Button from '@components/Shared/Layout/Button';
import FavouriteButton from '@components/Shared/Layout/FavouriteButton';
import ListItemData from '@components/Ads/List/ListItemData';

/* Styles */
import styles from './ListItemContent.module.scss';

/* Slots */
const slots = useSlots();

/* Props */
const props = defineProps({
  ad: {
    type: Object,
    required: true,
  },
  favouriteAds: {
    type: Object,
    required: true,
  },
});

/* Computed */
const badgeStatusDesign = computed(() => {
  if (['pending_review'].includes(props.ad.status)) {
    return 'warning';
  }

  if (['published'].includes(props.ad.status)) {
    return 'success';
  }

  return 'primary';
});
</script>
