<template>
  <div :class="styles.itemData">
    <span>
      <a :href="countyUrl">{{ county.name }}</a>
    </span>
    <span
      v-if="breeds && breeds.length"
      v-html="breeds.map((breed) => `<a href='/${adTypeSlug}/${breed.slug}'>${breed.name}</a>`).join(', ')"
    />
    <span v-if="age">
      Age: {{ age }}
    </span>
    <span v-if="dogsInfo">
      {{ dogsInfo }}
    </span>
  </div>
</template>

<script setup>
import { computed } from 'vue';

/* Styles */
import styles from './ListItemData.module.scss';

/* Props */
const props = defineProps({
  adType: {
    type: String,
    required: true,
  },
  age: {
    type: String,
    required: false,
    default: '',
  },
  breeds: {
    type: Array,
    required: false,
    default: null,
  },
  county: {
    type: Object,
    required: true,
  },
  dogsInfo: {
    type: String,
    required: false,
    default: '',
  },
});

/* Computed */
const adTypeSlug = computed(() => {
  if (props.adType === 'App\\Models\\StudAd') {
    return 'stud';
  }

  return 'sale';
});

const countyUrl = computed(() => {
  if (props.adType === 'App\\Models\\VetAd') {
    return `/vets/${props.county.slug}`;
  }

  return `/${adTypeSlug.value}?county=${props.county.slug}`;
});
</script>
