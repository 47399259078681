<template>
  <button
    :key="favourited"
    v-tooltip="favourited ? 'Remove from Favourites' : 'Add to Favourites'"
    :class="[styles.favouriteButton, { [styles.active]: favourited }]"
    type="button"
    @click="onClick"
  >
    <i class="fad fa-heart fa-solid" />
  </button>
</template>

<script setup>
import { getCurrentInstance, nextTick, onMounted, ref } from 'vue';
import { usePage, router } from '@inertiajs/vue3';

/* Styles */
import styles from './FavouriteButton.module.scss';

/* Emits */
const emit = defineEmits(['openGuestNoticeModal']);

/* Props */
const props = defineProps({
  favouritedId: {
    type: [Number, String],
    required: false,
    default: '',
  },
  modelId: {
    type: [Number, String],
    required: true,
  },
  modelType: {
    type: String,
    required: true,
  },
});

/* Constants */
const app = getCurrentInstance()?.appContext.config.globalProperties;

/* Data */
const favourited = ref(false);

/* Methods */
const removeFromFavourites = () => {
  router.delete(route('shared.favourites.destroy', props.favouritedId), {
    preserveScroll: true,
    onSuccess: async () => {
      app.$tooltips.destroy();
      favourited.value = false;
      await nextTick();
      app.$tooltips.init();
    },
  });
};

const addToFavourites = () => {
  router.post(route('shared.favourites.store'), {
    model_id: props.modelId.toString(), // TODO: fix this
    model_type: props.modelType,
  }, {
    preserveScroll: true,
    onSuccess: async () => {
      app.$tooltips.destroy();
      favourited.value = true;
      await nextTick();
      app.$tooltips.init();
    },
  });
};

const onClick = () => {
  if (!usePage().props.sharedData.user) {
    emit('openGuestNoticeModal');
    return;
  }

  if (favourited.value) {
    removeFromFavourites();
  } else {
    addToFavourites();
  }
};

/* Lifecycle Hooks: On Created */
favourited.value = Boolean(props.favouritedId);

/* Lifecycle Hooks: On Mounted */
onMounted(() => app.$tooltips.init());
</script>
