<template>
  <div :class="styles.item">
    <div :class="styles.itemBody">
      <ListItemThumbnail
        :url="ad.status === 'published' ? ad.url : null"
        :images-count="ad.images_count"
        :preview-url="ad.preview_url"
      />
      <ListItemContent
        :ad="ad"
        :favourite-ads="favouriteAds"
      >
        <template v-if="slots.buttons" #buttons>
          <slot name="buttons" />
        </template>
      </ListItemContent>
    </div>
    <slot name="info" />
  </div>
</template>

<script setup>
import { useSlots } from 'vue';

/* Components */
import ListItemContent from '@components/Ads/List/ListItemContent';
import ListItemThumbnail from '@components/Ads/List/ListItemThumbnail';

/* Styles */
import styles from './ListItem.module.scss';

/* Slots */
const slots = useSlots();

/* Props */
defineProps({
  ad: {
    type: Object,
    required: true,
  },
  favouriteAds: {
    type: Object,
    required: false,
    default: null,
  },
});
</script>
